import SurvivalImage from '@/assets/img/about-us/1_survival.png';
import EducationImage from '@/assets/img/about-us/2_education.png';
import ProtectionImage from '@/assets/img/about-us/3_protection.png';
import EnvironmentImage from '@/assets/img/about-us/4_environment.png';
import EqualityImage from '@/assets/img/about-us/5_equality.png';
import HumanismImage from '@/assets/img/about-us/6_humanism.jpg';
import SexualImage from '@/assets/img/about-us/7_sexual.jpg';
import Plus from '@/assets/img/icons/ico_plus@2x.png';
import Share from '@/assets/img/icons/ico_share@2x.png';
import Arrow from '@/assets/img/unicef/arrow_up@2x.png';
import Img1 from '@/assets/img/unicef/introduction_img1@2x.jpg';
import Img2 from '@/assets/img/unicef/introduction_img2@2x.jpg';
import Img3 from '@/assets/img/unicef/introduction_img3@2x.jpg';
import Img4 from '@/assets/img/unicef/introduction_img4@2x.jpg';
import Img5 from '@/assets/img/unicef/introduction_img5@2x.jpg';
import Logo from '@/assets/img/unicef/introduction_logo.png';
import LogoM from '@/assets/img/unicef/introduction_logoM@2x.png';
import Button from '@/components/Button';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

const usnisefData = [
  {
    id: 1,
    cate: `유니세프는`,
    tit: `신뢰할 수 있습니다`,
    dec: `유엔 기관이기 때문입니다. 유엔아동권리협약에 직접 명시된 유일한 아동권리증진 기관이자 1965년에 노벨평화상도 수상했습니다.`,
    img: Img1,
  },
  {
    id: 2,
    cate: `유니세프는`,
    tit: `같은 돈으로 더 많은 어린이를 구합니다`,
    dec: `유니세프는 약 80년의 경험과 전문성을 가지고 있으며 기부금으로 구호물품 등을 대량 구매하며 각국 정부와 협력해 근본적인 문제 해결을 추구하기 때문입니다.`,
    img: Img2,
  },
  {
    id: 3,
    cate: `유니세프는`,
    tit: `재난 발생시 가장 먼저 현장에 접근합니다`,
    dec: `유니세프는 약 190개 나라와 영토에서 활동하며, 5개의 물류센터를 보유해 전 세계 어디서 재난이 발생해도 가장 신속하게 현장에 접근하여 어린이를 돕습니다. 긴급상황이 종료된 후에도 한결같이 어린이 곁을 지키며 지속가능한 발전을 지원합니다.`,
    img: Img3,
  },
  {
    id: 4,
    cate: `유니세프는`,
    tit: `효율적이고 투명합니다`,
    dec: `유니세프한국위원회는 고유목적사업을 위해 매년 총 수입의 90% 이상을 국내외 어린이를 위해 사용합니다. 유니세프한국위원회는 내부감사와 외부회계법인 감사 및 유니세프 본부 감사를 통해 후원금 사용의 적정성을 검증받고 있으며, 국세청에 투명하게 공시하고 있습니다.`,
    img: Img5,
  },
  {
    id: 5,
    cate: `우리가`,
    tit: `도움을 줄 차례입니다`,
    dec: `유니세프는 1950년부터 1993년까지 43년간 수많은 한국 어린이의 소중한 생명을 지켰습니다. 이제는 우리가 개발도상국 어린이들에게도 꿈꿀 기회를 주어야 합니다.`,
    img: Img4,
  },
];
const SectionTop = styled(Section)`
  .dec {
    margin-bottom: 64px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${breakpoint(`tablet`)} {
  }
`;

const UsnisefFlex = styled.dl`
  display: flex;
  align-items: stretch;
  dt,
  dd {
    width: 50%;
  }
  dt {
    padding-right: 20px;
    padding-top: 96px;
    ${Tit} {
      display: block;
      line-height: 1.5;

      &.tit {
        margin-top: 2px;
      }
    }
    .dec {
      margin-top: 32px;
      line-height: 1.7;
      max-width: 450px;
    }
  }
  dd {
    position: relative;

    ${Image} {
      width: 100%;
    }
  }
  ${breakpoint(`mobile`)} {
    flex-wrap: wrap;
    dt,
    dd {
      width: 100%;
    }
    dt {
      padding: 0 20px;
      padding-top: 48px;
      height: 380px;

      .dec {
        line-height: 2;
      }
    }
    dd {
      ${Image} {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;
const SwiperCustom = styled(SwiperBasic)`
  padding: 0;
`;

const SectionDifferent = styled(Section)`
  .slide-container {
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 1920px;
      height: 100%;
      background: #f8f9fa;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
    }
  }
  ${SwiperCustom} {
    .swiper-container {
      padding-bottom: 0;
      overflow: hidden;
    }
    .swiper-slide {
      margin: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      bottom: 48px;
      right: auto;
      left: 0;
      display: block !important;
      z-index: 2;
    }
    .swiper-button-next {
      left: 80px;
    }
  }
  ${breakpoint(`mobile`)} {
    .slide-container {
      margin: 0 -20px;
    }
    ${SwiperCustom} {
      .swiper-container {
        padding-bottom: 0 !important;
      }
      .swiper-slide {
        width: 100%;
        margin-right: 0;
      }

      .swiper-button-prev,
      .swiper-button-next {
        bottom: auto;
        top: 352px;
        right: 20px;
        left: auto;
        z-index: 2;
      }
      .swiper-button-prev {
        right: 100px;
      }
    }
  }
`;

const SectionKorea = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 64px;
  }

  .list {
    & > li {
      position: relative;
      padding: 7px 0;
      padding-left: 56px;
      line-height: 1.7;
      margin-bottom: 32px;

      &::before {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        background: #1cabe2;
        border-radius: 100%;
        font-size: 20px;
        font-weight: 700;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:nth-child(1) {
        &::before {
          content: '1';
        }
      }
      &:nth-child(2) {
        &::before {
          content: '2';
        }
      }
      &:nth-child(3) {
        &::before {
          content: '3';
        }
      }
    }
  }
  .dec {
    color: #1cabe2;
  }
  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 48px;
    }

    .list {
      & > li {
        padding: 0;
        padding-left: 32px;
        line-height: 2;
        margin-bottom: 27px;

        &::before {
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 12px;
          top: 4px;
        }
      }
    }
  }
`;
const SectionHow = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 136px;
  }
  article {
    position: relative;
    padding: 184px 0 120px;

    &::before {
      content: '';
      display: block;
      width: 1920px;
      height: 100%;
      background: #f6fafc;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
    }
  }
  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 104px;
    }

    article {
      padding: 140px 0 64px;
    }
  }
  .btn-wrap {
    text-align: center;
    padding-bottom: 64px;
    position: absolute;
    left: 50%;
    top: -40px;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 832px;

    ${Button} {
      height: 80px;
      border-radius: 20px;
      text-align: center;

      .ico {
        width: auto;
        display: inline-block;
        padding-right: 32px;
        font-size: 24px;
      }
    }
  }

  ${SwiperCustom} {
    .swiper-container {
      padding: 0 12px 12px 0;
      margin-right: -12px;
      overflow: hidden;
      .swiper-slide {
        width: 198px;
        margin-right: 16px;
        overflow: initial;
      }

      .swiper-scrollbar {
        display: none;
        bottom: 0;
        width: 100%;
      }

      ${breakpoint(1160)} {
        padding-bottom: 28px;
        margin-right: 0;
        padding-right: 0;
        .swiper-scrollbar {
          display: block !important;
        }
      }
    }
  }

  .box {
    position: relative;
    z-index: 1;
    border: 3px solid #72c9eb;
    border-radius: 40px;
    margin-bottom: 115px;
    padding: 88px 30px 64px;

    &:last-child {
      margin-bottom: 0;
    }
    &.b1 {
      padding-top: 95px;
    }
    &.b2,
    &.b3 {
      background: #fff;
    }

    .box-tit {
      display: block;
      width: 100%;
      max-width: 544px;
      padding: 0 15px;
      padding-top: 54px;
      position: absolute;
      left: 50%;
      top: -81px;
      transform: translate(-50%, 0);

      &::before {
        content: '';
        display: block;
        width: 54px;
        height: 54px;
        background: url(${Arrow}) center no-repeat;
        background-size: cover;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
      }

      ${Tit} {
        display: inline-block;
        height: 60px;
        line-height: 60px;
        background: #72c9eb;
        text-align: center;
        border-radius: 20px;
        width: 100%;
      }
    }

    .box-sub-tit {
      display: block;
      width: 100%;
      max-width: 544px;
      margin: 0 auto;
      margin-top: 64px;
      margin-bottom: 32px;

      ${Tit} {
        display: inline-block;
        height: 60px;
        line-height: 60px;
        background: #e2f0f6;
        text-align: center;
        border-radius: 20px;
        width: 100%;
      }
    }

    .link-item {
      display: block;
      padding: 32px 10px;
      text-align: center;
      background: #fff;
      border-radius: 20px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.04);
      position: relative;
      border: 2px solid #fff;

      &:hover,
      &:active {
        border-color: #1cabe2;
      }

      &.t2 {
        padding: 50px 10px;
      }

      &::before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: url(${Share}) center no-repeat;
        background-size: cover;
        position: absolute;
        right: 12px;
        top: 12px;
      }

      ${Tit} {
        line-height: 1.6;
        margin: 2px 0;
        display: block;
      }
    }

    .item-flex {
      max-width: 568px;
      margin: 0 auto;

      & > li {
        width: 50%;
        padding: 0 8px;
        margin: 0;
      }
    }

    .item-flex2 {
      justify-content: center;

      & > li {
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 32px;
          height: 32px;
          background: url(${Plus}) center no-repeat;
          background-size: cover;
          position: absolute;
          right: -16px;
          top: 50%;
          transform: translate(0, -50%);
        }
        &:last-child::before {
          display: none;
        }

        ${Tit} {
          padding: 0 70px;

          ${breakpoint(1160)} {
            padding: 0 6.03vw;
          }
        }
      }
      ${breakpoint(`mobile`)} {
        flex-wrap: wrap;
        & > li {
          width: 50%;
          text-align: center;

          &:nth-child(3),
          &:nth-child(4) {
            margin-top: 48px;
          }
          &:nth-child(2n + 0) {
            &::before {
              display: none;
            }
          }
        }
      }
    }

    ${breakpoint(`tablet`)} {
      border-radius: 24px;
      margin-bottom: 100px;
      padding: 76px 15px 48px;

      .box-sub-tit {
        margin-top: 46px;
      }

      .item-flex.f1 {
        max-width: 198px;
        & > li {
          width: 100%;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

const SectionSignature = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 64px;
  }

  .box {
    padding: 64px 32px;
    border: 1px solid #e5e6e8;
  }

  .dl-wrap {
    dl {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }

      dd {
        line-height: 1.7;
        margin-top: 16px;
      }
    }
  }
  figure {
    margin-top: 48px;
    display: block;
    width: 100%;
    text-align: left;

    ${Image} {
      max-width: 985px;
    }
  }

  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 48px;
    }

    .box {
      padding: 48px 20px;
    }

    .dl-wrap {
      dl {
        margin-bottom: 24px;
      }
    }
  }
`;

const Introduction: React.FC<PageProps> = ({ location }) => (
  <LayoutWithTitle
    location={location}
    title="소개"
    description="for every child, UNICEF "
  >
    <SectionTop className="by-sub-main-layout">
      <Container>
        <p className="dec">
          <PageComponent id="content1">
            <Tit size="s1-5" color="sky" weight="normal">
              유니세프
            </Tit>
            는 1946년 ‘차별 없는 구호’의 정신으로 전 세계 어린이를 돕기 위해
            설립된 유엔기구입니다. 유니세프는 유엔아동권리협약에 직접 명시된
            유일한 아동권리 증진 기관이며 1965년에는 노벨 평화상도 수상했습니다.
            75년이 넘은 현장 경험과 전문성을 바탕으로 적은 돈으로 더 많은
            어린이의 생명을 살립니다. 각 나라 정부와 긴밀하게 협력해 보다
            효과적으로 폭넓게 어린이의 삶을 근본적으로 개선합니다. 약 190개 나라
            및 영토 등 전 세계 거의 모든 곳에서 활동하기 때문에 재난이 발생하면
            신속하게 긴급구호 사업을 펼칩니다. 재난 전에도, 재난 중에도, 재난
            후에도 한결같이 어린이 곁을 지킵니다. 단 한 명의 어린이도 빠짐없이,
            모든 어린이가 행복한 어린 시절을 보낼 수 있도록, 보건·영양·식수와
            위생·교육·긴급구호 사업을 펼칩니다. 지금 이 순간에도 유니세프는 전
            세계 어린이를 지킵니다.
          </PageComponent>
        </p>
        <p className="dec">
          <PageComponent id="content2">
            <Tit size="s1-5" color="sky" weight="normal">
              유니세프한국위원회
            </Tit>
            는 한국에서 유니세프를 대표해 유니세프의 이념을 실현합니다. 전 세계
            어린이를 위해 기금을 모으고 어린이 권리를 옹호합니다.
            <br />
            유니세프한국위원회는 후원금 100원 중 약 85원을 유니세프 본부로 송금하며
            전 세계 33개 유니세프국가위원회 중 가장 높은 수준의 송금률을
            자랑합니다. 국내 평가 전문 기관으로부터 높은 효율성과 투명성도
            인정받고 있습니다. 한국은 유니세프 역사상 도움을 받다가 도움을 주는
            나라로 전환된 유일한 국가이며, 2020년 5대 모금 국가로 성장했습니다.
            <br />
            유니세프한국위원회의 대표적인 어린이 권리 증진 사업은
            ‘유니세프아동친화사회 만들기’ 프로젝트입니다. 어린이가 생활하는
            사회(지역사회·학교 등)와 어린이에게 영향을 미치는 사회(기업·병원
            등)를 어린이에게 친화적인 환경으로 만듭니다. <br />
            유니세프한국위원회는 우리 모두가 행복한 사회를 만들기 위해 어린이가
            행복한 사회를 만듭니다.
          </PageComponent>
        </p>
      </Container>
    </SectionTop>

    <SectionDifferent className="by-sub-main-layout">
      <Container>
        <SectionHeader className="with-desc">
          <h2>
            <Tit size="s1">
              유니세프, <br />
              무엇이 다른가요?
            </Tit>
          </h2>
        </SectionHeader>
        <div className="slide-container">
          {/* <SwiperCustom>
            <Swiper slidesPerView={1} navigation> */}
          {usnisefData.map((row) => (
            <SwiperSlide key={row.id}>
              <UsnisefFlex>
                <dt>
                  <h3>
                    <Tit size="s2" color="sky" className="cate">
                      {row.cate}
                    </Tit>
                    <Tit size="s1-5" color="sky" weight="" className="tit">
                      {row.tit}
                    </Tit>
                  </h3>
                  <p className="dec">{row.dec}</p>
                </dt>
                <dd>
                  <Image pcSrc={row.img} mobileSrc={row.img} />
                </dd>
              </UsnisefFlex>
            </SwiperSlide>
          ))}
          {/* </Swiper>
          </SwiperCustom> */}
        </div>
      </Container>
    </SectionDifferent>

    <SectionKorea className="by-sub-main-layout">
      <Container>
        <SectionHeader className="with-desc">
          <h2>
            <Tit size="s1">
              한국이 <br />
              유니세프에서 세운 놀라운 기록
            </Tit>
          </h2>
        </SectionHeader>
        <ul className="list">
          <li>
            첫째, 유니세프 역사상 한국은 도움을 받다가 도움을 주는 나라로 전환한
            유일한 국가입니다.
          </li>
          <li>
            둘째, 한국은 1994년 유니세프한국위원회 설립 이후 2020년에는 미국,
            일본, 독일, 영국과 함께 5대 모금대국으로 성장했습니다.
          </li>
          <li>
            셋째, 유니세프한국위원회는 전 세계 33개 국가위원회 중 가장 높은
            수준의 효율성을 보이고 있습니다.
          </li>
        </ul>
        <strong className="dec">
          한 마디로, 한국은 왜 유니세프가 존재하는지 입증해줍니다.
        </strong>
      </Container>
    </SectionKorea>

    <SectionHow className="by-sub-main-layout">
      <Container>
        <SectionHeader className="with-desc">
          <h2>
            <Tit size="s1">
              유니세프, <br />
              무슨 일을 하나요?
            </Tit>
          </h2>
          <p className="header-dec">
            유니세프는 모든 어린이가 행복한 세상을 위해, 유엔아동권리협약과
            지속가능발전목표를 바탕으로 4년마다 중점 사업 계획을 세워 전 세계
            어린이의 권리와 복지를 실현합니다. <br />
            한국에서 유니세프를 대표해 유니세프의 이념을 실현하는
            유니세프한국위원회는 매년 유니세프 본부의 감사를 받으며 유니세프
            사업 계획에 맞춰 기금을 모으고 아동권리를 옹호합니다.
          </p>
        </SectionHeader>
        <article>
          <div className="btn-wrap">
            <Button
              ico="shareWhite"
              full
              onClick={() => navigate(`/about-us/unicef/mission`)}
            >
              <span className="ico">유니세프 미션</span>
            </Button>
          </div>

          <div className="box b1">
            <div className="box-tit">
              <Tit size="s4-1" color="white">
                유니세프 중점 사업 계획
              </Tit>
            </div>
            <SwiperCustom>
              <Swiper slidesPerView="auto" scrollbar={{ draggable: true }}>
                <SwiperSlide>
                  <LinkSafe
                    to="/what-we-do/no-discrimination/saving-lives"
                    className="link-item"
                  >
                    <Tit size="s4">모든 어린이의</Tit>
                    <Tit size="s4" color="sky">
                      생존과 발달
                    </Tit>
                    <Image
                      pcSrc={SurvivalImage}
                      mobileSrc={SurvivalImage}
                      style={{ width: '80px', marginTop: '40px' }}
                    />
                  </LinkSafe>
                </SwiperSlide>
                <SwiperSlide>
                  <LinkSafe
                    to="/what-we-do/no-discrimination/education"
                    className="link-item"
                  >
                    <Tit size="s4">모든 어린이의</Tit>
                    <Tit size="s4" color="sky">
                      교육
                    </Tit>
                    <Image
                      pcSrc={EducationImage}
                      mobileSrc={EducationImage}
                      style={{ width: '80px', marginTop: '40px' }}
                    />
                  </LinkSafe>
                </SwiperSlide>
                <SwiperSlide>
                  <LinkSafe
                    to="/what-we-do/no-discrimination/child-protection"
                    className="link-item"
                  >
                    <Tit size="s4">모든 어린이의</Tit>
                    <Tit size="s4" color="sky">
                      보호
                    </Tit>
                    <Image
                      pcSrc={ProtectionImage}
                      mobileSrc={ProtectionImage}
                      style={{ width: '80px', marginTop: '40px' }}
                    />
                  </LinkSafe>
                </SwiperSlide>
                <SwiperSlide>
                  <LinkSafe
                    to="/what-we-do/no-discrimination/wash"
                    className="link-item"
                  >
                    <Tit size="s4">모든 어린이의</Tit>
                    <Tit size="s5-l" color="sky">
                      안전하고 지속 가능한 환경
                    </Tit>
                    <Image
                      pcSrc={EnvironmentImage}
                      mobileSrc={SurvivalImage}
                      style={{ width: '80px', marginTop: '47px' }}
                    />
                  </LinkSafe>
                </SwiperSlide>
                <SwiperSlide>
                  <LinkSafe
                    to="/what-we-do/no-discrimination/fair-chance"
                    className="link-item"
                  >
                    <Tit size="s4">모든 어린이의</Tit>
                    <Tit size="s4" color="sky">
                      공평한 기회
                    </Tit>
                    <Image
                      pcSrc={EqualityImage}
                      mobileSrc={EqualityImage}
                      style={{ width: '80px', marginTop: '40px' }}
                    />
                  </LinkSafe>
                </SwiperSlide>
              </Swiper>
            </SwiperCustom>

            <div className="box-sub-tit">
              <Tit size="s4-1" color="sky">
                범분야 사업
              </Tit>
            </div>
            <ul className="item-flex flex f1">
              <li>
                <LinkSafe
                  to="/what-we-do/emergency-relief/humanitarian-aid"
                  className="link-item t2"
                >
                  <Tit size="s4">인도주의 사업</Tit>
                  <Image
                    pcSrc={HumanismImage}
                    mobileSrc={HumanismImage}
                    style={{ width: '80px', marginTop: '10px' }}
                  />
                </LinkSafe>
              </li>
              <li>
                <LinkSafe
                  to="/what-we-do/building-future/gender-equality"
                  className="link-item t2"
                >
                  <Tit size="s4">성 평등 사업</Tit>
                  <Image
                    pcSrc={SexualImage}
                    mobileSrc={SexualImage}
                    style={{ width: '80px', marginTop: '10px' }}
                  />
                </LinkSafe>
              </li>
            </ul>
          </div>

          {/* <div className="box b2">
            <h3>
              <Tit size="s4-1" color="white">
                유니세프 민간 부문 사업 계획
              </Tit>
            </h3>
            <ul className="item-flex2 flex">
              <li>
                <Tit size="s4" weight="black">
                  개인
                </Tit>
              </li>
              <li>
                <Tit size="s4" weight="black">
                  인플루언서
                </Tit>
              </li>
              <li>
                <Tit size="s4" weight="black">
                  기업
                </Tit>
              </li>
              <li>
                <Tit size="s4" weight="black">
                  정부
                </Tit>
              </li>
            </ul>
          </div> */}

          <div className="box b3">
            <div className="box-tit">
              <Tit size="s4-1" color="white">
                유니세프 국가위원회 공동 전략 계획
              </Tit>
            </div>
            <ul className="item-flex2 flex">
              <li>
                <Tit size="s4" weight="black">
                  기금 모금
                </Tit>
              </li>
              <li>
                <Tit size="s4" weight="black">
                  아동권리 옹호
                </Tit>
              </li>
            </ul>
          </div>
        </article>
      </Container>
    </SectionHow>

    <SectionSignature className="by-sub-main-layout">
      <Container>
        <SectionHeader className="with-desc">
          <h2>
            <Tit size="s1">유니세프 시그니처</Tit>
          </h2>
          <p className="header-dec">
            유니세프 시그니처에는 유니세프가 추구해야 하는 가치와 역할이 담겨
            있습니다.
          </p>
        </SectionHeader>
        <div className="box">
          <div className="dl-wrap">
            <dl>
              <dt>
                <Tit size="s3">엠블럼</Tit>
              </dt>
              <dd>
                유니세프 엠블럼은 부모가 아이를 들어 올리는 모습으로 희망, 안전,
                기쁨을 상징합니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s3">태그라인</Tit>
              </dt>
              <dd>
                ‘for every child’ 태그라인은 ‘for’는 유니세프가 최우선으로
                추구해야 하는 가치와 역할, ‘every’는 모든 어린이가 행복한 세상을
                위한 유니세프의 가치, <br />
                ‘child’는 단 한 명의 어린이도 빠짐없이 차별 없는 구호를 펼치는
                유니세프의 미션을 담고 있습니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s3">로고</Tit>
              </dt>
              <dd>
                유니세프 로고는 ‘평화’를 상징하는 컬러인 ‘유니세프 블루’로
                사용합니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s3">유니세프한국위원회</Tit>
              </dt>
              <dd>
                유니세프한국위원회는 유니세프 브랜드 가이드 라인에 따라 필요시
                유니세프 로고 밑에 국가명을 표기하여 사용합니다.
              </dd>
            </dl>
          </div>
          <figure>
            <Image pcSrc={Logo} mobileSrc={LogoM} />
          </figure>
        </div>
      </Container>
    </SectionSignature>
  </LayoutWithTitle>
);
export default Introduction;
